/**
 * Image listing page.
 */

import React from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar";
import {GatsbyImage} from "gatsby-plugin-image";

import JSONData from "../content/images.json"

const ImageIndex = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`;

    const imageList = {};
    for (const image of JSONData.content) {
        imageList[image.file] = image;
    }

    for (const imageNode of data.images.edges) {
        if (imageList[imageNode.node.base]) {
            imageList[imageNode.node.base]['node'] = imageNode.node;
        }
    }

    const imageArray = Object.entries(imageList);

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="Images"/>
            <Sidebar quote={data.quote} music={data.music}/>

            <ol className="post-grid">

                {imageArray.map((data, index) => {
                    const imageNode = data[1].node;
                    if (imageNode && imageNode.childImageSharp) {
                        return (
                            <li key={`content_item_${index}`} className="content">
                                <Link to={'/images/' + imageNode.base}>
                                    <GatsbyImage image={imageNode.childImageSharp.gatsbyImageData} alt={data[1].title}/>
                                </Link>
                            </li>
                        )
                    }
                    else {
                        return (
                            <li key={`content_item_${index}`}>Not an image?
                                {data[0]}
                            </li>
                        )
                    }
                })}
            </ol>
        </Layout>
    )
};

export default ImageIndex

export const pageQuery = graphql`
  query imageQuery(
    $quoteId: String
    $musicId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    images: allFile(filter: {relativeDirectory: {eq: "images"}, relativePath: {}}) {
        edges {
          node {
            id
            publicURL
            relativePath
            base
            childImageSharp {
                gatsbyImageData(
                  width: 200
                  height: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
            }
          }
        }
      }
    quote: markdownRemark(id: { eq: $quoteId }) {
      id
      html
      frontmatter {
        title
        author
        source
        source_link
      }
    }
    music: markdownRemark(id: { eq: $musicId }) {
    id
    frontmatter {
      title
      slug
      featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        }
    html
    }

  }
`;
